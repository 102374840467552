.cab-vertical-step {
  position: relative;
  min-height: 1em;
  color: gray;
}

.cab-vertical-step .cab-vertical-step-title {
  line-height: 1.5em;
  font-weight: bold;
}

.cab-vertical-step .cab-vertical-step-caption {
  font-size: 0.8em;
}


.cab-vertical-step + .cab-vertical-step {
  margin-top: 1.5em
}

.cab-vertical-step > div:first-child {
  position: static;
  height: 0;
}

.cab-vertical-step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}

/* Circle */
.cab-vertical-step .cab-vertical-step-circle {
  background: gray;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 2px #adb5bd;
  z-index: 3000;
}

/* Vertical Line */
.cab-vertical-step .cab-vertical-step-circle:after {
  content: ' ';
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: gray;
  z-index: 3001;
}

.cab-vertical-step:last-child .cab-vertical-step-circle:after {
  display: none
}

.cab-vertical-step.cab-vertical-step-active {
  color: #4285f4
}

.cab-vertical-step.cab-vertical-step-active .cab-vertical-step-circle {
  background-color: #4285f4;
}

.cab-vertical-step.cab-vertical-step-success {
  color: #5cb85c
}

.cab-vertical-step.cab-vertical-step-success .cab-vertical-step-circle {
  background-color: #5cb85c;
}

.cab-vertical-step.cab-vertical-step-warning {
  color: #bf2e29
}

.cab-vertical-step.cab-vertical-step-warning .cab-vertical-step-circle {
  background-color: #bf2e29;
}