@import '../adminkit/scss/light';
@import '../extensions/loading/loading';
@import '../extensions/ban-autocomplete/ban-autocomplete-light';
@import '../extensions/stepper/vertical/stepper';
@import '../extensions/top-message/top-message';
@import '../extensions/error-page/error-page';
@import '../extensions/breadcrumb/breadcrumb';
@import '../extensions/choicesjs/choicesjs';
@import '~cropperjs/dist/cropper.min.css';

.choices__inner {
  color: #495057;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
}

.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}