/*!
 * AdminKit PRO v3.3.0 (https://adminkit.io/)
 * Copyright 2022 Paul Laros
 * Copyright 2022 AdminKit
 */

// Variables
@import "1-variables/app";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Theme mixins
@import "2-mixins/badge";
@import "2-mixins/button";
@import "2-mixins/modal";
@import "2-mixins/switch";
@import "2-mixins/tabs";
@import "2-mixins/theme";

// Theme components
@import "3-components/accordion";
@import "3-components/alert";
@import "3-components/avatar";
@import "3-components/badges";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/chat";
@import "3-components/content";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/forms";
@import "3-components/hamburger";
@import "3-components/list-group";
@import "3-components/main";
@import "3-components/modal";
@import "3-components/navbar";
@import "3-components/pagination";
@import "3-components/progress";
@import "3-components/reboot";
@import "3-components/settings";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/stat";
@import "3-components/tables";
@import "3-components/tabs";
@import "3-components/timeline";
@import "3-components/type";
@import "3-components/wrapper";

// Theme utilities
@import "4-utilities/background";
@import "4-utilities/cursors";
@import "4-utilities/overflow";

// 3rd party plugins
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~apexcharts/src/assets/apexcharts";
@import "~choices.js/src/styles/choices";
@import "~datatables.net-bs5/css/dataTables.bootstrap5";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5";
@import "~datatables.net-responsive-bs5/css/responsive.bootstrap5";
@import "~datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5";
@import "~dragula/dist/dragula";
@import "~flatpickr/dist/flatpickr";
@import "~fullcalendar/main";
@import "~jsvectormap/dist/css/jsvectormap";
@import "~quill/dist/quill.bubble";
@import "~quill/dist/quill.snow";
@import "~simplebar/dist/simplebar";
@import "~notyf/notyf.min";

// 3rd party plugin styles
@import "5-vendor/datatables-select";
@import "5-vendor/datatables-fixedHeader";
@import "5-vendor/choices";
@import "5-vendor/flatpickr";
@import "5-vendor/fullcalendar";
@import "5-vendor/highlight";
@import "5-vendor/jsvectormap";
@import "5-vendor/quill";
@import "5-vendor/simplebar";