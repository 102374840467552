$flatpickr-tile-height: 45px;

.flatpickr-input[readonly],
.flatpickr-human[readonly] {
  background: $white;
}

.flatpickr-calendar.inline {
  background: transparent;
  box-shadow: none;
  width: 100%;
  
  .flatpickr-days {
    width: 100%;
  }

  .dayContainer {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .flatpickr-day {
    border-radius: $border-radius;
    max-width: inherit;
    height: $flatpickr-tile-height;
    line-height: $flatpickr-tile-height;

    &.today {
      border: 0;

      &:before {
        content: "";
        display: inline-block;
        border-color: rgba($black,.2) transparent $primary;
        border-style: solid;
        border-width: 0 0 7px 7px;
        position: absolute;
        bottom: 4px;
        right: 4px;
      }

      &.selected:before {
        border-color: rgba($black,.2) transparent $white;
      }

      &:hover {
        background: $gray-200;
        color: $black;
      }
    }

    &.selected {
      &,
      &:hover,
      &:focus {
        border-radius: $border-radius;
        background: $primary;
        color: $static-white;
      }
    }
  }

  .flatpickr-weekdays {
    height: $flatpickr-tile-height;
  }
  .flatpickr-weekday {
    height: $flatpickr-tile-height;
    line-height: $flatpickr-tile-height;
  }

  .flatpickr-months {
    .flatpickr-month {
      height: $flatpickr-tile-height;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      height: $flatpickr-tile-height;
    }
  }

  .flatpickr-current-month {
    padding-top: 0;
    line-height: $flatpickr-tile-height;
    height: $flatpickr-tile-height;

    .flatpickr-monthDropdown-months {
      appearance: none;
    }
    
    .flatpickr-monthDropdown-months,
    input.cur-year {
      font-weight: $font-weight-normal;
      font-size: $h4-font-size;
    }
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    width: 45px;
    border-radius: $border-radius;

    &:hover {
      background: $gray-300;
      color: $black;
    }
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months,
  .flatpickr-current-month input.cur-year {
    font-size: 1rem;
  }
}

.flatpickr-time {
  min-height: $input-height;
}

.flatpickr-calendar {
  background: $white;
}
.flatpickr-calendar {
  -webkit-box-shadow: 1px 0 0 $gray-200, -1px 0 0 $gray-200, 0 1px 0 $gray-200, 0 -1px 0 $gray-200, 0 3px 13px rgba($black,0.08);
          box-shadow: 1px 0 0 $gray-200, -1px 0 0 $gray-200, 0 1px 0 $gray-200, 0 -1px 0 $gray-200, 0 3px 13px rgba($black,0.08);
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 $gray-200, 5px 0 0 $gray-200;
          box-shadow: -2px 0 0 $gray-200, 5px 0 0 $gray-200;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid $gray-200;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: $gray-200;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $white;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: $gray-200;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: $white;
}
.flatpickr-months .flatpickr-month {
  color: rgba($black,0.9);
  fill: rgba($black,0.9);
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: rgba($black,0.9);
  fill: rgba($black,0.9);
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: $gray-500;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $danger;
}
.numInputWrapper span {
  border: 1px solid rgba($gray-800,0.15);
}
.numInputWrapper span:hover {
  background: rgba($black,0.1);
}
.numInputWrapper span:active {
  background: rgba($black,0.2);
}
.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba($gray-800,0.6);
}
.numInputWrapper span.arrowDown:after {
  border-top: 4px solid rgba($gray-800,0.6);
}
.numInputWrapper span svg path {
  fill: rgba($black,0.5);
}
.numInputWrapper:hover {
  background: rgba($black,0.05);
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba($black,0.05);
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba($black,0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba($black,0.9);
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba($black,0.5);
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba($black,0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: $white;
}
span.flatpickr-weekday {
  color: rgba($black,0.54);
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 $gray-200;
          box-shadow: -1px 0 0 $gray-200;
}
.flatpickr-day {
  color: $gray-800;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: $gray-200;
  border-color: $gray-200;
}
.flatpickr-day.today {
  border-color: $gray-500;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: $gray-500;
  background: $gray-500;
  color: $white;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $primary;
  color: $static-white;
  border-color: $primary;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 $primary;
          box-shadow: -10px 0 0 $primary;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 $gray-200, 5px 0 0 $gray-200;
          box-shadow: -5px 0 0 $gray-200, 5px 0 0 $gray-200;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba($gray-800,0.3);
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba($gray-800,0.1);
}
.flatpickr-day.week.selected {
  -webkit-box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
          box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  -webkit-box-shadow: 1px 0 0 $gray-200;
          box-shadow: 1px 0 0 $gray-200;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  color: rgba($gray-800,0.3);
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: $gray-800;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: $gray-800;
}
.flatpickr-time input {
  color: $gray-800;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: $gray-800;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: $gray-200;
}