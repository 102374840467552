footer.footer {
  padding: $spacer $spacer*0.875;
  direction: ltr;
  @include theme("theme", "background", $footer-bg);

  ul {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}
