.cab-top-message {
  @extend .text-center;
  @extend .bg-warning;
  //@extend .p-1;
  @extend .sticky-top;
  color: black;
  z-index: 5000;
}

.cab-top-message .title {
  font-size: 1.4em;
}