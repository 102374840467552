.cab-page-erreur {
  .erreur {
    border: 0;
    border-radius: 15px;
    margin: 0 auto;
    text-align: center;
  }

  .cab-error-title {
    font-size: 100%;
    font-weight: bold;
    padding: 0 !important;
  }

  .erreur i {
    color: red;
  }

  @media (max-width: 768px) {
    .cab-error-title {
      font-size: 4rem;
    }
  }
  @media (min-width: 768px) {
    .cab-error-title {
      font-size: 7rem;
    }
  }
}