.cab-loading-content {
  z-index: 6000;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
}

.cab-loading-spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
}